import auth from "../middlewares/auth";
import hasDue from "@/middlewares/hasDue";
import aweber from "@/middlewares/aweber";
export const promptManagerRoutes = [
  {
    path: '/prompt-manager',
    component: () => import(/* webpackChunkName: "prompt_manager" */ '../views/prompt_manger/PromptManager.vue'),
    name: 'prompt_manager',
    meta: {
      middleware: [
        auth, hasDue, aweber
      ]
    }
  },
];