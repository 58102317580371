<template>
  <footer class="footer-section theme-footer">
    <div class="footer-section-1  sidebar-theme"></div>
    <div class="footer-section-2 container-fluid">
        <div class="row">
            <div id="toggle-grid" class="col-xl-7 col-md-6 col-sm-6 col-12 text-sm-left text-center"></div>
            <div class="col-xl-5 col-md-6 col-sm-6 col-12">
                <ul class="list-inline mb-0 d-flex justify-content-sm-end justify-content-center mr-sm-3 ml-sm-0 mx-3">
                    <li class="list-inline-item  mr-3">
                        <p class="bottom-footer"> &copy; 2022 Attribution Insight <span class="text-muted d-none d-sm-inline-block float-end"></span></p>
                    </li>
                    <li class="list-inline-item align-self-center">
                        <scroll-top></scroll-top>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </footer>
</template>


<script>
import ScrollTop from "@/components/ScrollTop";

export default {
  components: {ScrollTop},

}
</script>