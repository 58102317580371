import auth from "../middlewares/auth";
import hasDue from "../middlewares/hasDue";
export const licenseRoutes = [
  {
    path: '/licenses',
    component: () => import(/* webpackChunkName: "licenses" */ '../views/licenses/List.vue'),
    name: 'licenses',
    meta: {
      middleware: [
        auth, hasDue
      ]
    }
  },
];