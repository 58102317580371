export default {
  namespaced: true,
  state () {
    return {
      selectedUserIds: [],
      users: []
    }
  },
  mutations: {
    setSelectedUserIds(state, payload) {
      state.selectedUserIds = payload;
    },
    setUsers(state, payload) {
      state.users = payload
    }
  },
  getters: {
    getSelectedUserIds: (state) => state.selectedUserIds,
    getUsers: (state) => state.users,
  }
}