<template>
    <div class="scrollTop" @click="scrollTop"><i class="flaticon-up-arrow-fill-1"></i></div>
</template>

<script>

export default {
    mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
    },
    beforeUnmount: function () {
        window.removeEventListener('scroll', this.scrollListener)
    },

    methods: {
        scrollTop: function () {
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                clearInterval(this.intervalId)
                }
                window.scroll(0, window.pageYOffset - 50)
            }, 20)
        },
    },
}
</script>