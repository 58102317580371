import { createStore } from 'vuex'
import authModule from './modules/auth'
import createCampaignModule from './modules/createCampaignModule'

const store = createStore({
  state () {
    return {

    }
  },
  mutations: {

  },
  actions: {

  },
  getters: {
  },

  modules: {
    auth: authModule,
    createCampaign: createCampaignModule,
  }
})

export default store