export default function hasDue ({ next }) {
  /*let payment = store.state.auth.payment;

  if (payment && payment.has_due) {
    return next({
      name: 'invoices'
    })
  }*/

  return next()
}