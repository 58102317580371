<template>
  <div class="form-control-feedback text-danger" v-for="(err, index) in errors" :key="key(index)">
    {{ err }}
  </div>
</template>

<script>
export default {
  props: ['errors'],
  methods: {
    key(index) {
      return `validation_error_${index}_${Math.random()}`
    }
  }
}
</script>