import auth from "../middlewares/auth";
export const invoiceRoutes = [
  {
    path: '/invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '../views/invoices/List.vue'),
    name: 'invoices',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];