import auth from "../middlewares/auth";
import hasDue from "../middlewares/hasDue";
export const campaignRoutes = [
  {
    path: '/create-campaign',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign/Create.vue'),
    name: 'create_campaign',
    meta: {
      middleware: [
        auth, hasDue
      ]
    }
  },
  {
    path: '/send-email',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign/SendMail.vue'),
    name: 'send_email',
    meta: {
      middleware: [
        auth, hasDue
      ]
    }
  },
];