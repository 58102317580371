import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      user: JSON.parse(localStorage.getItem('user'))|| null,
      payment: null,
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setPayment(state, payload) {
      state.payment = payload;
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', payload).then((response) => {
          let user = response.data.data;
          commit('setUser', user);
          localStorage.setItem('user', JSON.stringify(user));

          axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

          resolve(user);
        }).catch((error) => {
          reject(error.response)
        });
      });
    },
    getPayment({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setPayment', null);

        axios.get('/payment-status', payload).then((response) => {
          let payment = response.data.data;
          commit('setPayment', payment);

          resolve(payment);
        }).catch((error) => {
          reject(error.response)
        });
      });
    },
    logout(context) {
      return new Promise( (resolve) => {
        axios.post('/logout')
          .finally(() => {
            context.dispatch('resetUser');
            resolve(true);
          });
      });
    },
    resetUser({commit}) {
      commit('setUser', null);
      localStorage.removeItem('user');
      axios.defaults.headers.common['Authorization'] = null;
    }
  },
  getters: {
    getUser: (state) => state.user,
    getPayment: (state) => state.payment,
  }
}