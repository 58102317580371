import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";
import hasDue from "@/middlewares/hasDue";
import { licenseRoutes } from "@/routes/licenseRoutes";
import { invoiceRoutes } from "@/routes/invoiceRoutes";
import { campaignRoutes } from "@/routes/campaignRoutes";
import { promptManagerRoutes } from "@/routes/promptManagerRoutes";

let routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth, hasDue
      ]
    }
  },
  {
    path: '/invitation',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Invitation.vue'),
    name: 'invitation',
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
]

routes.push(...licenseRoutes);
routes.push(...invoiceRoutes);
routes.push(...campaignRoutes);
routes.push(...promptManagerRoutes);

export default routes;
